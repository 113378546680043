import { frenchTerritoryIsoCodes } from '@openclassrooms/location/constants';

import { availableRegions, AvailableRegions } from '../constants';

type CountryGroup = {
  mainCountryIsoCode: AvailableRegions;
  territories: readonly string[];
};

const COUNTRY_GROUPS: CountryGroup[] = [
  {
    mainCountryIsoCode: 'FR',
    territories: frenchTerritoryIsoCodes,
  },
];

function isHandledCountry(country: any): country is AvailableRegions {
  return availableRegions.includes(country);
}

export default function getConfigurationCountry(targetCountry: string): AvailableRegions | 'INT' {
  if (isHandledCountry(targetCountry)) {
    return targetCountry;
  }

  const foundCountryGroup = COUNTRY_GROUPS.find((countryGroup) =>
    countryGroup.territories.includes(targetCountry)
  );

  if (foundCountryGroup) {
    return foundCountryGroup.mainCountryIsoCode;
  }

  return 'INT';
}
